<template>
  <v-container class="page-container" fill-height fluid grid-list-xl
  >
    <v-layout wrap id="configuration">
      <div class="page-header w-100">
        <div class="flex-display flex-row flex-justify-between mb-3 w-100">
          <h1 class="page-title mb-2">
            {{ pageTitle }}
          </h1>
        </div>
        <div class="flex-display flex-row flex-justify-between mb-3 w-100">
          <div class="top-search">
            <input class="search-container"
                   type="text"
                   :placeholder="search.placeholder"
                   v-model="search.searchQuery"
                   v-on:keyup.enter="searchRecords"
            >
            <p class="mt-2 ml-2">
              Total sessions: {{ weeklyTotalSessions }}
            </p>
          </div>
        </div>
        <div class="filters">
          <!-- Therapist availability filter -->
          <single-select-filter v-if="availability.length > 0"
                                :id="'time-filter'"
                                :all-items-label="'All Psychologists'"
                                :items="availability"
                                :selected="selectedAvailability"
                                v-on:change="changeAvailability"
                                v-on:clear="clearAvailabilitySelection"
          ></single-select-filter>
          <!-- Slot type filter -->
          <single-select-filter v-if="types.length > 0"
                                :id="'types-filter'"
                                :all-items-label="'All Types'"
                                :items="types"
                                :selected="selectedType"
                                v-on:change="changeType"
                                v-on:clear="clearTypeSelection"
          ></single-select-filter>
          <!-- Day time filter -->
          <single-select-filter v-if="timePeriods.length > 0"
                                :id="'time-filter'"
                                :all-items-label="'All'"
                                :items="timePeriods"
                                :selected="selectedTimePeriod"
                                v-on:change="changeTimePeriod"
                                v-on:clear="clearTimePeriodSelection"
          ></single-select-filter>

          <!-- Default Schedule Filter -->

          <single-select-filter v-if="defaultScheduleFilter.length > 0"
                                :show-all-option="false"
                                :id="'default-schedule-filter'"
                                :items="defaultScheduleFilter"
                                :selected="selectedDefaultScheduleFilter"
                                v-on:change="changeDefaultScheduleFilter"
          ></single-select-filter>
          <dashboard-booking-filters
                  :filters="bookingFilters"
                  @applyBookingFilters="applyScheduleFilters"
          />
          <!-- Show schedule of the therapists accepting these organizations as insurance-->
          <template v-if="insuranceProviders.length > 0">
                <multi-select-filters :filter-id="'insurance_providers'"
                                      class="ml-2 mb-3"
                                      :filters="insuranceProviders"
                                      :placeholder="'Select Insurance Provider'"
                                      :column="'insurance_company_id'"
                                      :query-type="'external'"
                                      :relation-ship-name="''"
                ></multi-select-filters>
          </template>
          <template v-if="issuesFilter.length > 0">
                <multi-select-filters :filter-id="'issues_filter'"
                                      class="ml-2 mb-3"
                                      :filters="issuesFilter"
                                      :placeholder="'Select Issue'"
                                      :column="'issues'"
                                      :operator="'find_in_set'"
                ></multi-select-filters>
          </template>
          <template v-if="treatmentsFilter.length > 0">
                <multi-select-filters :filter-id="'treatments_filter'"
                                      class="ml-2 mb-3"
                                      :filters="treatmentsFilter"
                                      :placeholder="'Select Treatment'"
                                      :column="'treatment'"
                                      :operator="'find_in_set'"
                ></multi-select-filters>
          </template>
        </div>

        <div class="filters">
          <template v-if="regionsFilter.length > 0">
            <multi-select-filters :filter-id="'regions_filter'"
                                  class="ml-2 mb-3"
                                  :filters="regionsFilter"
                                  :placeholder="'Indemnity coverage'"
                                  :column="'region_id'"
                                  :operator="'equal_to'"
                                  :relation-ship-name="''"
                                  :query-type="'external'"
            ></multi-select-filters>
          </template>
        </div>

      </div>
      <div class="schedule-container">
        <div class="schedule-header">
          <img class="pointer" src="/icons/calendar_previous_ic.svg" @click="previous" alt="myonlinetherapy">
          <span class="header-title mx-4">{{ formatDateTime(start) }} - {{ formatDateTime(end) }}</span>
          <img class="pointer" src="/icons/calendar_next_ic.svg" @click="next" alt="myonlinetherapy">
        </div>
        <div class="schedule-content" id="schedule_content" v-if="schedule">
          <weekly-calendar :time-span="timeSpan" :schedule="schedule"></weekly-calendar>
        </div>
      </div>
    </v-layout>

  </v-container>
</template>

<script>
import SingleSelectFilter from "@/components/common/SingleSelectFilter";
import MultiSelectFilter from "@/components/common/MultiSelectFilter";
import WeeklyCalendar from "@/components/common/calendar/WeeklyCalendar";
import timeHelper from "../helpers/timeHelper";
import MultiSelectFilters from "@/components/common/dataTable/filters/MultiSelectFilters";
import DashboardBookingFilters from "@/components/main/DashboardBookingFilters";

export default {
  name: "Schedule",
  components: {
    SingleSelectFilter,
    MultiSelectFilter,
    WeeklyCalendar,
    MultiSelectFilters,
    DashboardBookingFilters
  },
  data() {
    return {
      pageTitle: 'Schedule',
      search: {
        placeholder: 'Search by name',
        searchQuery: ''
      },
      queryParams: {
        page: 1,
        search: '',
        sort: 'first_name|asc'
      },
      defaultScheduleFilter: [],
      therapists: [],
      types: [], // Type Filter
      timePeriods: [],
      availability: [], // Availability Filter
      selectedTherapists: [],
      selectedType: null,
      selectedTimePeriod: null,
      selectedAvailability: null,
      selectedDefaultScheduleFilter: {
        'id': 'accept_new_clients',
        'name': ''
      },
      schedule: [],
      timeSpan: [],
      insuranceProviders: [],
      issuesFilter: [],
      treatmentsFilter: [],
      regionsFilter: [],
      hasMore: true,
      start: timeHelper.firstDayOfCurrentWeek(),
      end: timeHelper.lastDayOfCurrentWeek(),
      weeklyTotalSessions: 0,
      selectedScheduleFilters: [],
      bookingFilters: [],
    }
  },
  computed: {
    filteredTherapists: {
      get() {
        const self = this;
        return self.therapists.filter((th) => {
          return th.name.toLowerCase().includes(self.search.searchQuery.toLowerCase());
        });
      }
    },
  },
  created() {
    this.getFilters();
    this.getSchedule();
  },
  methods: {
    getTotalSessions() {
      let sum = 0;
      $.each(this.timeSpan, function (key, value) {

        sum = sum + value.slots_count;

      });
      this.weeklyTotalSessions = sum;
    },
    getFilters() {
      this.$http.get(this.url + 'schedule/filters').then(response => {
        this.defaultScheduleFilter = response.data.default_schedule_filter;
        this.types = response.data.types;
        this.timePeriods = response.data.time_period;
        this.availability = response.data.availability;
        this.selectedDefaultScheduleFilter = this.defaultScheduleFilter[0];
        this.insuranceProviders = response.data.insurance_providers;
        this.issuesFilter = response.data.issues_filter;
        this.treatmentsFilter = response.data.treatments_filter;
        this.regionsFilter = response.data.regions_filter;
      });
    },
    getSchedule() {
      let loader = this.$loading.show();
      this.$http.get(this.url + 'schedule', {
        params: {
          ...this.queryParams,
          type: this.selectedType ? this.selectedType.id.toLowerCase() : 'all',
          time: this.selectedTimePeriod ? this.selectedTimePeriod.id.toLowerCase() : 'all',
          availability: this.selectedAvailability ? this.selectedAvailability.id.toLowerCase() : 'all',
          filter_by: this.selectedDefaultScheduleFilter.id,
          from: this.start,
          to: this.end,
          filters: this.selectedScheduleFilters
        }
      }).then(response => {
        this.timeSpan = response.data.aggregate;
        this.schedule = response.data.schedule;
        this.getTotalSessions();
        loader.hide();
      }).catch(error => {
          loader.hide();
      });
    },
    searchRecords() {
      this.queryParams.search = this.search.searchQuery;
      this.getSchedule();
    },
    searchTherapists(searchQuery) {
      this.queryParams.search = searchQuery;
      this.getFilters();
    },
    applyTherapistsFilter(selectedTherapists) {
      this.selectedTherapists = selectedTherapists;
    },
    changeType(type) {
      this.selectedType = type;
      this.getSchedule(true);
    },
    clearTypeSelection() {
      this.selectedType = null;
      this.getSchedule(true);
    },
    changeTimePeriod(timePeriod) {
      this.selectedTimePeriod = timePeriod;
      this.getSchedule(true);
    },
    clearTimePeriodSelection() {
      this.selectedTimePeriod = null;
      this.getSchedule(true);
    },
    changeAvailability(availability) {
      this.selectedAvailability = availability;
      this.getSchedule(true);
    },
    clearAvailabilitySelection() {
      this.selectedAvailability = null;
      this.getSchedule(true);
    },
    changeDefaultScheduleFilter(filter) {
      this.selectedDefaultScheduleFilter = filter;
      this.getSchedule(true);
    },
    previous() {
      this.start = timeHelper.addWeek(this.start, -1);
      this.end = timeHelper.addWeek(this.end, -1);
      this.getSchedule();
    },
    next() {
      this.start = timeHelper.addWeek(this.start, 1);
      this.end = timeHelper.addWeek(this.end, 1);
      this.getSchedule();
    },
    formatDateTime(datetime) {
      return timeHelper.formatDateTime(datetime, 'ddd Do MMM')
    },
    applyScheduleFilters(param) {
      let index = this.selectedScheduleFilters.findIndex(x => x.filter_id.toLowerCase() === param.filter_id.toLowerCase());
      if (index >= 0) {
        this.selectedScheduleFilters.splice(index, 1);
      }
      this.selectedScheduleFilters.push(param);
      this.getScheduleAnalytics();
    },
     getScheduleAnalytics() {
       this.getSchedule();
     },
  }
}
</script>

<style scoped>
#core-view {
  padding-bottom: 0;
}

.top-search {
  display: inline-flex;
}
</style>